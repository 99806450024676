// =============================================================================
//
//  Login Page Style Desktop
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width) {
    .login {
        &-wrapper {
            padding-bottom: $login-wrapper__padding-bottom--large;

            .tabs-header {
                border-bottom: $tabs-header__border-bottom;

                &::before {
                    content: none;
                }

                > .item {
                    @include get-font('medium');
                }
            }

            input[type='email'],
            input[type='number'],
            input[type='password'],
            input[type='search'],
            input[type='tel'],
            input[type='text'],
            input[type='url'],
            input[type='color'],
            input[type='date'],
            input[type='datetime'],
            input[type='datetime-local'],
            input[type='month'],
            input[type='time'],
            input[type='week'],
            textarea,
            select {
                padding: $login-form-input__padding--large;
            }

            .tabs-content > .item.active.hidden {
                display: none !important;
            }
        }

        &-sections-container {
            @include flexbox();
            @include flex-wrap(wrap);
        }

        &-heading {
            @include get-font('enormous-gigantic');
            margin: $login-page-heading__margin--large;
        }

        &-section-left,
        &-section-right {
            @include flex($login-sections-left-right__flex--large);
        }

        &-section-left {
            padding-right: $login-sections-left-right-side__padding--large;
            border-right: $login-sections-left__border-right--large;

            .tabs-header {
                position: unset;
                overflow: initial;
                width: auto;
                margin: 0;

                > .item {
                    padding: $login-page-tab-item__padding--large;
                    margin: 0;
                }

                > .item:first-child {
                    padding-left: 0;
                }
            }

            .tabs-content {
                margin-top: $login-page-tabs-content__margin-top--large;
            }
        }

        &-section-right {
            padding-left: $login-sections-left-right-side__padding--large;
        }

        &-form-bottom {
            margin-top: $login-form-bottom__margin-top--large;
        }

        &-button-wrapper {
            margin-top: $login-button-wrapper__margin-top--large;

            .login-button {
                max-height: $login-button__max-height--large;
                padding: $login-button__padding;
                line-height: $login-button__line-height--large;         
            }
        }

        &-create-account-button-wrapper {
            margin-top: $login-create-account-button-wrapper__margin-top--large;

            .login-create-account-button {
                max-height: $login-button__max-height--large;
                padding: $login-button__padding;
                line-height: $login-button__line-height--large;         
            }
        }

        &-track-order-heading {
            @include get-font('medium');
            margin-top: 0;

            &::after {
                content: none;
            }
        }

        &-track-order-header-text {
            @include get-font('small');
            margin: $login-track-your-order-text__margin--large;
        }

        &-track-order-button {
            max-height: $login-button__max-height--large;
            padding: $login-button__padding;
            margin-top: $login-track-order-button__margin-top--large;
            line-height: $login-button__line-height--large; 
        }

        &-send-email-button-wrapper {
            margin-bottom: $login-send-email-button-wrapper__margin-bottom--large;
        }

        &-cancel-password-button {
            @include get-font('small');
        }

        &-request-new-password {
            width: $login-request-new-password__width--large;
            margin: $login-request-new-password__margin--large;
        }

        &-bottom-content {
            padding: $login-bottom-content__padding--large;
        }

        &-bottom-content-list-item {
            .icon-check {
                @include get-font('small');
            }
        }

        &-bottom-content-body {
            margin: $login-bottom-content-body__margin--large;
        }
    }
}

// IE compatibility
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .login {
        &-section-left,
        &-section-right {
            @include flex($ie-login-section-left-right__flex--large);
        }
    }
}
