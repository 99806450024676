// =============================================================================
//
//  Login Page Style Tablet
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@media screen and (min-width: $tablet-width) {
    .login {
        &-wrapper {
            .tabs-header {
                > .item {
                    padding: $tabs-header-item__padding;
                }
            }
        }
    }
}
